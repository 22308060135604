

































































































import Vue from 'vue'
import Component from 'vue-class-component'
import i18n from '@/i18n'

@Component({
  layout: 'blank',
})
export default class OrderPaymentInvoice extends Vue {
  h = this.$route.query.h       // Hash
  s = this.$route.params.siteId // Shop ID
  o = this.$route.query.o       // Order ID
  lang = this.$route.query?.lang
    ? this.$route.query.lang.toString()
    : 'sv'

  data = {
    currency: "",
    paymentMethod: "",
    paymentDate: "",
    email: "",
    total: 0,
  }
  valid = true
  isError = false
  isReady = false
  isEmailed = false
  isNotPaid = 0
  maxRetries = 15 // NOTE: Make sure this will exceed cronjob interval for check-fortus-for-queue.php (Current is every minute)
  errorMessage = this.$t('c:payment:Unable to load invoice details, contact support if you are not expecting this')
  emailErrorMessage = ""

  created() {
    i18n.setLanguage(this.lang)
    this.getInvoiceInfo()
  }

  getInvoiceInfo() {
    this.$axios.get(`/v3/order/payment/get_invoice_info?h=${this.h}&s=${this.s}&o=${this.o}`)
      .then(response => {
        if (!response || !response?.data?.data) {
          this.isError = true
        } else {
          this.isReady = true
          this.isError = false
          this.isNotPaid = 0
          this.data = response.data.data
        }
      })
      .catch(error => {
        this.isError = true
        if (error?.response?.data?.errors.length) {
          if (error.response.data.errors[0].type === 'not_paid') {
            this.isNotPaid = this.isNotPaid + 1
            if (this.isNotPaid < this.maxRetries) {
              // NOTE: First call will trigger backend queue, so wait longer before next call
              const timeOut = this.isNotPaid === 1 ? 5000 : 1000 + (this.isNotPaid * 1000)
              setTimeout(() => {
                this.getInvoiceInfo()
              }, timeOut) // Retry with backoff
            }
          } else {
            this.isNotPaid = 0
          }
          if (error.response.data.errors[0].type !== 'generic') {
            this.errorMessage = error.response.data.errors[0].message
          }
        }
      })
  }

  emailInvoice() {
    this.$axios.get(`/v3/order/payment/email_invoice?h=${this.h}&s=${this.s}&o=${this.o}&e=${this.data.email}`)
      .then(() => {
        this.isEmailed = true
      })
      .catch(error => {
        if (error?.response?.data?.errors.length) {
          if (error.response.data.errors[0].type !== 'generic') {
            this.emailErrorMessage = error.response.data.errors[0].message
          }
        }

        alert(this.$t('Unable to email invoice, please contact support or try again later'));
      })
  }

  refreshPage() {
    document.location.reload()
  }
}
